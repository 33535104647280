/* global $, $script, google, addKeyword, toggleFixedBody, KEYWORD_ICON_SRC, JOB_ICON_SRC, MAX_DATE, DATE_ENDS */

// Helper functions

// Find Parent Element with css class
function findAncestor(el, classSelector) {
  while ((el = el.parentElement) && !el.classList.contains(classSelector));
  return el;
}

// IE doesn't support forEach over NodeList so use this instead
function forEachElement(nodeList, fn) {
  if (nodeList) {
    for (var i = 0; i < nodeList.length; i++) {
      var element = nodeList[i];
      fn(element);
    }
  }
}

function elementInNodeList(el, nodeList) {
  for (var i = 0; i < nodeList.length; i++) {
    var listEl = nodeList[i];
    if (listEl == el) {
      return true;
    }
  }
  return false;
}

// Find child element with css class
function findChild(el, classSelector) {
  if (el) {
    var children = el.children;
    for (var i = 0; i < children.length; i++) {
      var child = children[i];
      if (child.classList.contains(classSelector)) {
        return child;
      }
    }
  }
  return undefined;
}

function findSibling(el, classSelector) {
  var potentialSibling = el.nextElementSibling;
  if (!potentialSibling) {
    return null;
  }

  if (potentialSibling.classList.contains(classSelector)) {
    return potentialSibling;
  } else {
    return findSibling(potentialSibling, classSelector);
  }
}

function handleCopy(e) {
  var parent = findAncestor(e.target, 'form2__input__icon__wrapper');
  var input = findChild(parent, 'form2__input');
  var msg = findChild(parent, 'form2__input__copy-msg');
  copy(input.value);

  msg.classList.add('form2__input__copy-msg--animate');
  setTimeout(function () {
    msg.classList.remove('form2__input__copy-msg--animate');
  }, 3 * 1000);
}

function copy(str) {
  var el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}

// Check value
function isEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

function hasAtSign(url) {
  return url && url.includes('@');
}

function isCAPS(txt) { return txt === txt.toUpperCase(); }

// Check field type
function isEmailField(el) {
  if (el.getAttribute('type') === 'email') return true;
  if (el.dataset.type === 'email') return true;
  return false;
}

function isUrlField(el) {
  if (el.getAttribute('type') === 'url') return true;
  if (el.dataset.type === 'url') return true;
  return false;
}

function isKeywordField(el) {
  if (el.dataset.type === 'keyword') return true;
  return false;
}

function isDatePickerField(el) {
  if (el.getAttribute('id') === 'id_date_ends') return true;
  return false;
}

function isHidden(el) {
  return (el.offsetParent === null)
}

function noCAPS(el) { return el.dataset.nocaps; }

function getWarningMessage(el) {
  if (el && el.getAttribute('name') === 'apply_link' && hasAtSign(el.value)) {
    return 'Laitathan tähän kenttään verkkosivun osoitteen!';
  }
  return '';
}

// Error messages
function getErrorMsg(el, fileFail, fileLarge) {
  var defaultMsg = 'Tarkista tämä tieto ennen ilmoituksen julkaisua.';

  // (Include file fail message here just to keep things contained)
  if (fileFail) return 'Kuva on väärää tiedostotyyppiä. Lisää kuva .jpg .png .svg tai .gif -muodossa.';

  if (fileLarge) return 'Lisäämäsi kuva on liian suuri! Liitteiden enimmäiskoko on 20 Mt.';

  // No element given -> general error message
  if (!el) return defaultMsg;

  // Email
  if (isEmailField(el) && !isEmail(el.value)) return 'Täytäthän sähköpostiosoitteesi muodossa nimesi@esimerkki.fi';

  // Url
  if (isUrlField(el) && el.value !== '') return 'Täytäthän osoitteen muodossa http(s)://yritys.fi tai yritys.fi';

  // Keywords
  if (isKeywordField(el)) return 'Lisääthän korkeintaan 10';

  // CAPS
  if (noCAPS(el) && el.value && isCAPS(el.value)) return 'Älä käytä otsikossa pelkästään ISOJA KIRJAIMIA';

  // Date picker
  if (isDatePickerField(el)) return 'Suosittelemme vähintään 2 viikon hakuaikaa parhaiden mahdollisten tulosten saavuttamiseksi.';

  // Length not within provided range
  if (el.minLength && el.minLength > 0 && el.minLength > el.value.length) return 'Täytäthän tähän vähintään ' + el.minLength + ' merkkiä';
  if (el.maxLength && el.maxLength > 0 && el.maxLength <= el.value.length) {
    if (el.maxLength > 10000 || el.maxLength < 0) return 'Kentän maksimipituus ylitetty';
    return 'Täytäthän tähän vain ' + el.maxLength + ' merkkiä'
  }

  if (el.type === 'checkbox') {
    return 'Käyttöehdot ja tietojesi käsittely tulee hyväksyä'
  }

  // Nothing matched -> general error message
  return defaultMsg;
}

function showErr(el, parent, txt) {
  if (el) {
    el.innerHTML = txt;
    el.style.display = 'inline-block';
  }
  if (parent) {
    parent.classList.add('form2__input__error');
  }
}

function hideErr(el, parent) {
  if (el) {
    el.innerHTML = '';
    el.style.display = 'none';
  }
  if (parent) {
    parent.classList.remove('form2__input__error');
  }
}

function scrollToFirstError() {
  var errorEls = document.querySelectorAll(".form2__input__error");
  for (var i = 0; i < errorEls.length; i++) {
    var errorEl = errorEls[i];
    if (errorEl.style.display !== 'none') {
      var rect = errorEl.getBoundingClientRect();
      window.scrollTo(rect.left, rect.top);
      return;
    }
  }
}

// Form validation
function validateInput(el) {
  var message, warningMessage;
  var elType = el.getAttribute('type');
  var elId = el.getAttribute('id');

  // Image data is stored in hidden field, but error is shown in visible one
  if (elType === 'hidden' && elId === 'id_jobentry_logo') {
    clearImgInput('id_jobentry_logo');
    el = document.querySelector('#id_jobentry_logo');
  } else if (elType === 'hidden' && elId === 'id_banner_image') {
    clearImgInput('id_banner_image');
    el = document.querySelector('#id_banner_image');
  } else if (['id_salary_low', 'id_salary_high', 'id_salary_value', 'id_salary_period'].indexOf(elId) !== -1) {
    // Salary fields need special validation :S
    message = getSalaryErrorMessage(el, elId);
    if (!message) {
      // low - high relation error cannot be set for single field
      // needs special handling that sets it only for low
      message = resolveSalaryMultiFieldError(el, elId)
    }
    displaySalaryError(el, elId, message)
    return !!message
  }

  message = getErrorMessage(el)
  warningMessage = getWarningMessage(el);

  displayError(el, message || warningMessage);
  return !!message
}

function validateRequiredInput(el) {
  // only for elements that are required
  var message = '';
  if (!el.value || (el.type === 'checkbox' && !el.checked)) {
    message = getErrorMsg(el);
  }

  displayError(el, message);
  return !!message;
}

function getErrorMessage(el) {
  // Hidden elements cannot have errors
  if (isHidden(el)) {
    return '';
  }
  if (typeof (el.checkValidity) === 'function' && !el.checkValidity()) {
    return getErrorMsg(el)
  } else {
    // check file size
    if (el.files && el.files[0] && el.files[0].size > 20000000) {
      return getErrorMsg(el, false, true);
    }

    // check caps
    if (noCAPS(el) && isCAPS(el.value)) {
      return getErrorMsg(el);
    }

    if (el.minLength && el.minLength > 0 && el.minLength > el.value.length) {
      return getErrorMsg(el)
    }

    if (el.maxLength && el.maxLength > 0 && el.maxLength <= el.value.length) {
      return getErrorMsg(el);
    }
  }
  return '';
}


function displayError(el, message) {
  var errParent = findAncestor(el, 'form2__input__wrapper');
  if (!errParent) {
    errParent = findAncestor(el, 'form2__input--select__wrapper');
  }
  var errEl = findChild(errParent, 'form2__input__error__text');

  if (!errEl) {
    errEl = findSibling(el, 'form2__input__error__text');
    errParent = el;
  }

  if (!errEl) return false

  if (message) {
    showErr(errEl, errParent, message);
    return true
  }
  hideErr(errEl, errParent);
  return false
}

// Display descr errors
function validateDescription() {
  var descrEl = document.querySelector('#id_descr_html');
  var descrContent = document.querySelector(".form2__rte__content");
  var descrParent = document.querySelector(".form2__rte.form2__rte--last-el")

  if (descrContent && descrParent) {
    var minLength = descrEl.minLength;
    var maxLength = descrEl.maxLength;

    if (descrContent.innerHTML.length < minLength) {
      displayError(descrParent, "Syötäthän vähintään 3 merkkiä.");
      return true;
    } else if (descrContent.innerHTML.length > maxLength) {
      displayError(descrParent, "Kuvausteksti on liian pitkä.");
      return true;
    } else {
      displayError(descrParent, '');
    }
  }

  return false;
}

function getSalaryErrorMessage(el, elId) {
  if (isHidden(el)) {
    return '';
  }
  var value;
  var max_value = 9999999.99;
  if (elId === 'id_salary_low') {
    value = parseFloat(el.value)
    if (value < 0) {
      return 'Minimipalkka pitää olla vähintään 0'
    }
    if (value > max_value) {
      return 'Minimipalkka saa olla korkeintaan ' + max_value
    }
  } else if (elId === 'id_salary_high') {
    value = parseFloat(el.value)
    if (value < 0) {
      return 'Maksimipalkka pitää olla vähintään 0'
    }
    if (value > max_value) {
      return 'Maksimipalkka saa olla korkeintaan ' + max_value
    }
  } else if (elId === 'id_salary_value') {
    value = parseFloat(el.value)
    if (value < 0) {
      return 'Palkka pitää olla vähintään 0'
    }
    if (value > max_value) {
      return 'Palkka saa olla korkeintaan ' + max_value
    }
  }
  return getErrorMessage(el)
}

function resolveSalaryMultiFieldError(el, elId) {
  if (isHidden(el)) {
    return '';
  }
  var lowEl = document.querySelector('#id_salary_low');
  var highEl = document.querySelector('#id_salary_high');
  var lowValue, highValue;
  var max_value = 9999999.99;
  var errorMsg = 'Minimipalkka ei saa olla maksimia suurempi';
  if (elId === 'id_salary_low') {
    lowValue = parseFloat(el.value);
    if (highEl) {
      highValue = parseFloat(highEl.value);
      if (isNaN(lowValue) && !isNaN(highValue)) {
        return 'Syötä myös minimipalkka';
      } else if (!isNaN(lowValue) && isNaN(highValue)) {
        displaySalaryError(highEl, 'id_salary_high', 'Syötä myös maksimipalkka');
        return '';
      } else if (highValue >= 0 && highValue <= max_value) {
        if (highValue < lowValue) {
          // Show value in low, hide in max
          displaySalaryError(highEl, 'id_salary_high', getSalaryErrorMessage(highEl, 'id_salary_high'));
          return errorMsg;
        }
      }
    }

  } else if (elId === 'id_salary_high') {
    highValue = parseFloat(el.value);
    if (lowEl) {
      lowValue = parseFloat(lowEl.value)
      if (isNaN(lowValue) && !isNaN(highValue)) {
        displaySalaryError(lowEl, 'id_salary_low', 'Syötä myös minimipalkka');
        return '';
      } else if (!isNaN(lowValue) && isNaN(highValue)) {
        return 'Syötä myös maksimipalkka';
      } else if (lowValue >= 0 && lowValue <= max_value) {
        if (lowValue > highValue) {
          // Show value in low, hide from max
          displaySalaryError(lowEl, 'id_salary_low', errorMsg);
        } else {
          // Low doesn't have anymore this error, check if it has something else
          displaySalaryError(lowEl, 'id_salary_low', getSalaryErrorMessage(lowEl, 'id_salary_low'));
        }
      }
    }
  }

  var periodEl = document.querySelector('#id_salary_period');
  var valueEl = document.querySelector('#id_salary_value');
  var singleSalaryEl = document.querySelector('#id_salary_single');

  // If salary period is selected, check if salary range is within reasonable bounds in relation to it
  if (periodEl.value) {
    highValue = parseFloat(highEl.value);
    lowValue = parseFloat(lowEl.value);
    if (singleSalaryEl.checked){
      highValue = parseFloat(valueEl.value);
      lowValue = highValue;
    }
    if (
      (periodEl.value == 1 && (lowValue < 1 || highValue > 500)) ||
      (periodEl.value == 4 && (lowValue < 100 || highValue > 100000))
    ) {
      errorMsg = 'Tarkastaisitko luvut ja ajanjakson.';

      if (elId === 'id_salary_period') {
        return errorMsg;
      }

      displaySalaryError(periodEl, 'id_salary_period', errorMsg);
    }
    // All checks are good, reset error
    else {
      displaySalaryError(periodEl, 'id_salary_period', '');
    }
  }
  // If salary period is not selected, check if any of the salary values have been inputted
  else if (lowEl.value || highEl.value || valueEl.value) {
    errorMsg = 'Ajanjakso puuttuu. Valitse onko palkka tunti- vai kuukausiperusteinen.';

    if (elId === 'id_salary_period') {
      return errorMsg;
    }

    displaySalaryError(periodEl, 'id_salary_period', errorMsg);
  }

  return '';
}

function displaySalaryError(el, elId, message) {
  var errParent;
  if (elId === 'id_salary_low') {
    errParent = document.querySelector('#salary_low_error');
  } else if (elId === 'id_salary_high') {
    errParent = document.querySelector('#salary_high_error');
  } else if (elId === 'id_salary_value') {
    errParent = document.querySelector('#salary_value_error');
  } else if (elId === 'id_salary_period') {
    errParent = document.querySelector('#salary_period_error');
  } else {
    displayError(el, message)
  }
  if (errParent) {
    var errEl = findChild(errParent, 'form2__input__error__text');
    errParent = findAncestor(errParent, 'form2__input__wrapper');
    if (message) {
      showErr(errEl, errParent, message);
    } else {
      hideErr(errEl, errParent)
    }
  }
}

// Handle File Input
function bindFileInputs(inputs) {
  forEachElement(inputs, function (el) {
    var target = document.querySelector(el.dataset.imageBox);

    // When a file is uploaded
    function handleFileSelection(e) {
      clearImgUrlInput(e.target.id);
      handleFile(el.files[0], document.querySelector('#' + e.target.id + '--box'));
    }

    // When element is dragged and hovered over the element
    function dragStart() {
      target.classList.add('form2__image__box--drag-hovering');
    }

    // When element drag-hovering over the element stops
    function dragStop() {
      target.classList.remove('form2__image__box--drag-hovering');
    }

    // Attach listeners
    ['dragover', 'dragenter'].forEach(function (x) { el.addEventListener(x, dragStart) });
    ['dragend', 'dragleave', 'drop'].forEach(function (x) { el.addEventListener(x, dragStop) });
    el.addEventListener('change', handleFileSelection);
    el.addEventListener('focus', handleFileInputFocus);
    el.addEventListener('blur', handleFileInputBlur);
  });
}

function handleFileInputFocus(e) {
  var selector = '.btn[for="' + e.target.id + '"]';
  document.querySelector(selector).classList.add('form2__input--btn--focused');
}

function handleFileInputBlur(e) {
  var selector = '.btn[for="' + e.target.id + '"]';
  document.querySelector(selector).classList.remove('form2__input--btn--focused');
}


function clearFileSizeExceedError(el) {
  var errParent = findAncestor(el, 'form2__input__wrapper');
  var errEl = findChild(errParent, 'form2__input__error__text');
  hideErr(errEl, errParent);
}

function handleFile(file, el) {
  if (!file || !el) return;

  // check file size
  if (file.size > 20000000) {
    displayError(el, getErrorMsg(el, false, true));
    return;
  }

  // clear the previous file size alert if there is
  clearFileSizeExceedError(el);

  var reader = new FileReader();

  reader.onload = function (e) {
    imageToDOM(e.target.result, findChild(el, 'form2__image__box'));
    loadedToDOM(el);
  }

  // Set up file reader for displaying uploaded image
  reader.readAsDataURL(file);
  loadedToDOM(el);
}


// Image Input
function imageToDOM(url, el) {
  el.style.backgroundImage = 'url(' + url + ')';
}

function loadedToDOM(el) {
  el.classList.add('file-is-uploaded');
}

function clearImgInput(id) {
  // Replace input element
  // Writing to file input with js is not supported in all browsers -> gotta replace the old input el with new
  var old = document.querySelector('#' + id);
  var input = document.createElement('input');
  var attrs = ['type', 'name', 'class', 'id', 'data-image-box'];

  // clear error message when clear image
  clearFileSizeExceedError(old);

  attrs.forEach(function (x) { old.getAttribute(x) && input.setAttribute(x, old.getAttribute(x)); });

  var parent = old.parentNode;
  parent.replaceChild(input, old);

  // Bind event handlers to new input
  bindFileInputs([input]);

  clearImgPreview(id);
}

function clearImgUrlInput(id) {
  document.querySelector('#' + id + '_url').value = '';
  clearImgPreview(id);
}

function clearImgPreview(id) {
  document.querySelector('#' + id + '--box').classList.remove('file-is-uploaded');
  document.querySelector('#' + id + '--box .form2__image__box').style.backgroundImage = 'unset';
}

function clearImage(e) {
  e.preventDefault();

  clearImgInput(e.target.dataset.targetId);
  clearImgUrlInput(e.target.dataset.targetId);
}

function showServerCreatedErrors() {
  var errorTexts = document.querySelectorAll('.form2__input__error__text')
  forEachElement(errorTexts, function (errorTextEl) {
    var errorMessage = errorTextEl.innerHTML.replace(/\s+/g, ' ').trim();

    // Salaries have different structure, custom handling
    var salaryErrorParent = findAncestor(errorTextEl, 'js-salary-error');
    if (salaryErrorParent) {
      var parentId = salaryErrorParent.id;
      var input;
      if (parentId === 'salary_value_error') {
        input = document.querySelector('#id_salary_value');
        displaySalaryError(input, 'id_salary_value', errorMessage);

      } else if (parentId === 'salary_low_error') {
        input = document.querySelector('#id_salary_low');
        displaySalaryError(input, 'id_salary_low', errorMessage);

      } else if (parentId === 'salary_high_error') {
        input = document.querySelector('#id_salary_high');
        displaySalaryError(input, 'id_salary_high', errorMessage);
      }
    } else {
      displayError(errorTextEl, errorMessage);
    }
  });
}

function previouslyInputImageUrlsToDOM() {
  forEachElement(document.querySelectorAll('input'), function (x) {
    if (!x.value) return;

    // Remove '_url' from the end
    var target = x.id.substring(0, x.id.length - 4);
    if (!target) return;

    var selector = '#' + target + '--box'

    var el = document.querySelector(selector);
    if (!el) return;

    loadedToDOM(el);
    imageToDOM(x.value, document.querySelector(selector + ' .form2__image__box'));
  });
}

function handleSubmit(event, requiredEls) {
  var otherEls = document.querySelectorAll(
    '.form2__input, .form2__image__hidden-drag, .form2__input__wrapper [type="hidden"], .form2__input--checkbox'
  );
  var hasErrors = validateInputs(otherEls, requiredEls);
  var hasDescriptionErrors = validateDescription();

  if (hasErrors || hasDescriptionErrors) {
    // Capture submit event to handle errors in a user friendly way
    event.preventDefault();
    scrollToFirstError();
  } else {
    beforeSubmit()
    showLoadingModal();
  }
}

function validateInputs(elements, requiredElements) {
  var hasErrors = false;
  forEachElement(elements, function (el) {
    // Normal value validation
    var error = validateInput(el);
    if (error) {
      hasErrors = true;
    } else {
      // Validate required elements separately, since we removed
      // browser validation for required elements
      // Required element errors are less specific so do this
      // after normal validation that can return richer messages
      var isRequired = elementInNodeList(el, requiredElements);
      if (isRequired) {
        error = validateRequiredInput(el);
        if (error) {
          hasErrors = true;
        }
      }
    }
  });
  return hasErrors
}

function beforeSubmit() {
  var hasMultipleAreas = document.querySelector('#id_has_multiple_areas');
  var additionalAreas = document.querySelector('#id_additional_areas');

  // Make sure additional areas is empty if user did not select
  // multiple areas checkbox
  if (hasMultipleAreas && additionalAreas) {
    if (!hasMultipleAreas.checked) {
      additionalAreas.value = "";
    }
  }
}

function showLoadingModal() {
  var loadingElem = document.querySelector('.modal-loading');
  if (!loadingElem) return;
  loadingElem.classList.add('modal-loading--visible');
}

function removeAllChildNodes(parent) {
  while (parent.firstChild) {
    parent.removeChild(parent.firstChild);
  }
}

function handleAutocompleteCompany(ui) {
  if (ui.item.customer_service_info) {
    const customerServiceInfoWrapper = document.querySelector('#customer-service-info');
    removeAllChildNodes(customerServiceInfoWrapper);
    const header = document.createElement('label');
    const headerText = document.createTextNode('Lisätiedot asiakaspalvelijalle');
    const paragraph = document.createElement('p');
    const paragraphText = document.createTextNode(ui.item.customer_service_info);
    paragraph.setAttribute('id', 'customer-service-info-text');
    header.appendChild(headerText);
    header.htmlFor = 'customer-service-info-text';
    header.className = 'form2__label';
    paragraph.appendChild(paragraphText);
    customerServiceInfoWrapper.appendChild(header);
    customerServiceInfoWrapper.appendChild(paragraph);
  }
  if (!ui.item.logo) return;

  var logoUrlField = document.querySelector('#id_jobentry_logo_url');
  if (!logoUrlField) return;

  logoUrlField.value = ui.item.logo;
  clearImgInput('id_jobentry_logo');
  imageToDOM(ui.item.logo, document.querySelector('[data-image-target-id="id_jobentry_logo"] .form2__image__box'));
  loadedToDOM(document.querySelector('#id_jobentry_logo--box'));
}

function handleAutocompleteKeyword(ui) {
  var kwInput = document.querySelector('#id_keywords');

  if (window.jobentry_keywords.length >= 10) {
    displayError(kwInput, getErrorMessage(kwInput));
    return;
  }

  displayError(kwInput, '');
  addKeyword(ui.item);
}


function toggleAdminModal(ev) {
  ev.preventDefault();
  var element = document.querySelector('.js-modal__admin-form');
  element.classList.toggle('modal--hidden');

  element = document.querySelector('.js-modal__admin-form--overlay');
  element.classList.toggle('modal-overlay--visible');
  if (typeof (toggleFixedBody) === 'function') {
    toggleFixedBody();
  }
}

function toggleApplyInfoModal(ev) {
  ev.preventDefault();
  var element = document.querySelector('.js-modal__apply-info');
  element.classList.toggle('modal--hidden');

  element = document.querySelector('.js-modal__apply-info--overlay');
  element.classList.toggle('modal-overlay--visible');
  if (typeof (toggleFixedBody) === 'function') {
    toggleFixedBody();
  }
}

function toggleEmailInfoModal(ev) {
  ev.preventDefault();
  var element = document.querySelector('.js-modal__email-info');
  element.classList.toggle('modal--hidden');

  element = document.querySelector('.js-modal__email-info--overlay');
  element.classList.toggle('modal-overlay--visible');
  if (typeof (toggleFixedBody) === 'function') {
    toggleFixedBody();
  }
}

function changeApplyMethod(applyMethod) {
  hideAll(document.querySelectorAll('.js-apply-section'));

  if (applyMethod === 'duunitori-hakemukset') {
    showAll(document.querySelectorAll('.js-apply-duunitori-hakemukset'));
  } else if (applyMethod === 'duunitori-cvless') {
    showAll(document.querySelectorAll('.js-apply-duunitori-cvless'));
  } else if (applyMethod === 'link') {
    showAll(document.querySelectorAll('.js-apply-link'));
  }
}

function toggleSalaryInfoModal(ev) {
  ev.preventDefault();
  var element = document.querySelector('.js-modal__salary-info');
  element.classList.toggle('modal--hidden');

  element = document.querySelector('.js-modal__salary-info--overlay');
  element.classList.toggle('modal-overlay--visible');
  if (typeof (toggleFixedBody) === 'function') {
    toggleFixedBody();
  }
}

function toggleDiversityPromiseInfoModal(ev) {
  ev.preventDefault();
  var element = document.querySelector('.js-modal__diversity-promise-info');
  element.classList.toggle('modal--hidden');

  element = document.querySelector('.js-modal__diversity-promise-info--overlay');
  element.classList.toggle('modal-overlay--visible');
  if (typeof (toggleFixedBody) === 'function') {
    toggleFixedBody();
  }
}

function showAll(nodeList, useInlineBlock) {
  forEachElement(nodeList, function (el) {
    if (useInlineBlock) {
      el.style = 'display: inline-block;'
    } else {
      el.style = 'display: block;'
    }
  })
}

function hideAll(nodeList) {
  forEachElement(nodeList, function (el) {
    el.style = 'display: none;'
  })
}

function showNextApplyEmail(ev) {
  var applyEmails = document.querySelectorAll('.js-next-apply-email');
  var i;
  for (i = 0; i < applyEmails.length; i++) {
    var applyEmailEl = applyEmails[i];
    if (applyEmailEl.style.display == 'none') {
      applyEmailEl.style = 'display: block;';
      break;
    }
  }
  // No more elements hidden
  if (i === applyEmails.length - 1) {
    ev.target.style = 'display: none;'
  }
}

function showMultipleAreasInitial() {
  let additionalAreas = document.querySelector('#id_additional_areas')

  if (!additionalAreas || !additionalAreas.value) {
    return;
  }

  let multipleAreasWrapper = document.querySelector('#multiple_areas_wrapper');

  if (multipleAreasWrapper === null) {
    return;
  }
  multipleAreasWrapper.style = "display: block;"

  let multipleAreasButton = document.querySelector('#multiple_areas_button');
  if (multipleAreasButton === null) {
    return;
  }

  multipleAreasButton.style = "display: none;";
}

function showMultipleAreas(ev) {
  ev.preventDefault();
  let multipleAreasWrapper = document.querySelector('#multiple_areas_wrapper');
  if (multipleAreasWrapper === null) {
    return;
  }

  multipleAreasWrapper.style = "display: block;";

  let multipleAreasButton = document.querySelector('#multiple_areas_button');
  if (multipleAreasButton === null) {
    return;
  }

  multipleAreasButton.style = "display: none;";
}

function changeSalaryFieldsVisibility(showSingle) {
  var showFields, hideFields;
  if (showSingle) {
    showFields = document.querySelectorAll('.js-salary-value');
    hideFields = document.querySelectorAll('.js-salary-low, .js-salary-high');
  } else {
    showFields = document.querySelectorAll('.js-salary-low, .js-salary-high');
    hideFields = document.querySelectorAll('.js-salary-value');
  }
  if (showFields) {
    showAll(showFields, true);
    forEachElement(showFields, function (x) {
      var input = findChild(x, 'form2__input');
      if (input) {
        validateInput(input);
      }
    });
  }
  if (hideFields) {
    hideAll(hideFields);
    // Hide errors in hidden fields
    forEachElement(hideFields, function (x) {
      var input = findChild(x, 'form2__input');
      if (input) {
        displaySalaryError(input, input.id, '');
      }
    });
  }
}

// Init all event handlers
(function init() {
  // preprocess DOM
  previouslyInputImageUrlsToDOM();
  showServerCreatedErrors();
  showMultipleAreasInitial();

  // Description is created dynamically, bind event listener with MutationObserver
  if (MutationObserver) {
    var descrParent = document.querySelector(".form2__rte.form2__rte--last-el");
    if (descrParent) {
      var descrObserver = new MutationObserver(function () {
        var descrContent = document.querySelector(".form2__rte__content");
        if (descrContent) {
          descrContent.addEventListener("blur", function () {
            validateDescription()
          })
          // Disconnect observer once event listener is bound
          descrObserver.disconnect();
          // Scroll to first error once description is loaded
          // description box changes page size significantly otherwise
          scrollToFirstError();
        }
      });
      descrObserver.observe(descrParent, { subtree: true, childList: true });
    }
  }

  var form = document.querySelector('#jobentry_form')
  if (form) {
    // Default form validation doesn't show our custom errors, so
    // disable required fields and check them manually
    // in handleSubmit
    var requiredEls = document.querySelectorAll('[required]');
    forEachElement(requiredEls, function (el) {
      el.removeAttribute('required');
    })
    form.addEventListener("submit", function (ev) {
      handleSubmit(ev, requiredEls);
    });
  }

  let multipleAreasButton = document.querySelector('#multiple_areas_button');
  if (multipleAreasButton) {
    multipleAreasButton.addEventListener("click", showMultipleAreas)
  }

  var fileInputs = document.querySelectorAll('input[type="file"]');
  bindFileInputs(fileInputs);

  var clearImageBtns = document.querySelectorAll('.js-clear-image-input');
  forEachElement(clearImageBtns, function (x) {
    if (x) {
      x.addEventListener('click', clearImage);
    }
  });

  // Validate inputs when changed
  [
    document.querySelectorAll('.form2__input'),
    document.querySelectorAll('.form2__image__hidden-drag'),
    document.querySelectorAll('.form2__input__wrapper [type="hidden"]'),
    document.querySelectorAll('.form2__input--checkbox'),
  ].forEach(function (nodeLists) {
    forEachElement(nodeLists, function (e) {
      if (e) {
        e.addEventListener('change', function (event) {
          validateInput(event.target);
        });
        e.addEventListener('blur', function (event) {
          validateInput(event.target);
        });
      }
    });
  })

  var dateEl = document.querySelector('#id_date_ends');
  if (dateEl) {
    dateEl.addEventListener('focus', function (e) {
      e.preventDefault();
    });
  }

  var companyInput = document.querySelector('#id_company_name');
  if (companyInput) {
    companyInput.addEventListener('keyup', function (e) {
      const customerServiceInfoWrapper = document.querySelector('#customer-service-info');
      removeAllChildNodes(customerServiceInfoWrapper);
    });
  }

  function checkDateEnds() {
    var dateEnds = $(dateEl).datepicker('getDate');
    var currentDate = new Date();
    var diff = (dateEnds.getTime() - currentDate.getTime()) / 1000;
    diff /= 60 * 60 * 24 * 7;
    if (diff < 2) {
      var message = getErrorMsg(dateEl);
      displayError(dateEl, message);
    }
  }

  var copyFieldEls = document.querySelectorAll('.js-copy-input');
  forEachElement(copyFieldEls, function (x) {
    x.addEventListener('click', handleCopy)
  });

  var adminModalToggles = document.querySelectorAll('.js-toggle-admin-modal');
  forEachElement(adminModalToggles, function (x) {
    x.addEventListener('click', toggleAdminModal);
  });

  var applyInfoModalToggles = document.querySelectorAll('.js-toggle-apply-info-modal');
  forEachElement(applyInfoModalToggles, function (x) {
    x.addEventListener('click', toggleApplyInfoModal);
  });

  var emailInfoModalToggles = document.querySelectorAll('.js-toggle-email-info-modal');
  forEachElement(emailInfoModalToggles, function (x) {
    x.addEventListener('click', toggleEmailInfoModal);
  });

  var salaryInfoModalToggles = document.querySelectorAll('.js-toggle-salary-info-modal');
  forEachElement(salaryInfoModalToggles, function (x) {
    x.addEventListener('click', toggleSalaryInfoModal);
  });

  var diversityPomiseInfoModalToggles = document.querySelectorAll('.js-toggle-diversity-promise-info-modal');
  forEachElement(diversityPomiseInfoModalToggles, function (x) {
    x.addEventListener('click', toggleDiversityPromiseInfoModal);
  });

  var applyMethodSelect = document.querySelector('#id_apply_method');
  if (applyMethodSelect) {
    applyMethodSelect.addEventListener('change', function (ev) {
      changeApplyMethod(ev.target.value);
    });
    changeApplyMethod(applyMethodSelect.value);
  }

  var showNextApplyEmailButton = document.querySelector('.js-show-next-apply-email');
  if (showNextApplyEmailButton) {
    showNextApplyEmailButton.addEventListener('click', showNextApplyEmail);
  }

  var singleSalaryCheckbox = document.querySelector('#id_salary_single');
  if (singleSalaryCheckbox) {
    singleSalaryCheckbox.addEventListener('click', function (ev) {
      changeSalaryFieldsVisibility(ev.target.checked)
    })
    changeSalaryFieldsVisibility(singleSalaryCheckbox.checked)
  }

  $script.ready(["jquery", "ui"], function () {
    // Date ends
    if (dateEl) {
      var maxDate = "+30";
      if (MAX_DATE) {
        maxDate = MAX_DATE;
      }
      $(dateEl).datepicker({
        minDate: 1,
        maxDate: maxDate,
        constrainInput: true,
        dateFormat: "d.m.yy",
        showAnim: "fadeIn",
        firstDay: 1,
        onSelect: function () { checkDateEnds(); },
        dayNamesMin: ["Su", "Ma", "Ti", "Ke", "To", "Pe", "La"],
        monthNames: ["Tammikuu", "Helmikuu", "Maaliskuu", "Huhtikuu", "Toukokuu", "Kesäkuu", "Heinäkuu", "Elokuu", "Syyskuu", "Lokakuu", "Marraskuu", "Joulukuu"],
        showOn: 'both',
        buttonImage: 'data:image/svg+xml;utf8,<svg width="19" height="20" xmlns="http://www.w3.org/2000/svg"><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="7.1.-Ilmon-jättö-desktop---default-väliotsikoilla" transform="translate(-1056 -1851)" fill="%23081531" fill-rule="nonzero"><g id="icons8-calendar_filled-copy-3" transform="translate(1056 1851)"><path d="M4.13 0a.836.836 0 0 0-.826.833v.834H.826c-.48 0-.826.35-.826.833v2.917h19V2.5c0-.483-.347-.833-.826-.833h-2.478V.833A.836.836 0 0 0 14.87 0h-.827a.836.836 0 0 0-.826.833v.834H5.783V.833A.836.836 0 0 0 4.957 0H4.13zm0 .833h.827v2.5H4.13v-2.5zm9.913 0h.827v2.5h-.827v-2.5zM0 6.25v12.917c0 .483.347.833.826.833h17.348c.48 0 .826-.35.826-.833V6.25H0zm4.13 2.5h2.066v2.083H4.13V8.75zm2.892 0h2.065v2.083H7.022V8.75zm2.891 0h2.065v2.083H9.913V8.75zm2.891 0h2.066v2.083h-2.066V8.75zM4.13 11.667h2.066v2.083H4.13v-2.083zm2.892 0h2.065v2.083H7.022v-2.083zm2.891 0h2.065v2.083H9.913v-2.083zm2.891 0h2.066v2.083h-2.066v-2.083zM4.13 14.583h2.066v2.084H4.13v-2.084zm2.892 0h2.065v2.084H7.022v-2.084zm2.891 0h2.065v2.084H9.913v-2.084zm2.891 0h2.066v2.084h-2.066v-2.084z" id="Shape"/></g></g></g></svg>',
        beforeShow: function () { $('#ui-datepicker-div').addClass('notranslate'); }
      });

      if (DATE_ENDS) {
        $(dateEl).datepicker('setDate', DATE_ENDS);
      }
    }

    // Autocomplete
    var autocompleteCache = { company: {}, keyword: {} };
    $('[data-autocomplete]').each(function () {
      var $input = $(this);
      var url = $input.data('autocomplete');
      var cache = $input.data('autocomplete-name');
      var icon = cache === 'keyword' ? KEYWORD_ICON_SRC : JOB_ICON_SRC;

      if (url) {
        $input.autocomplete({
          minLength: 2,
          source: function (request, response) {
            var term = request.term;
            if (term in autocompleteCache[cache]) {
              response(autocompleteCache[cache][term]);
              return;
            }
            $.getJSON(url, { name: term }, function (data) {
              var results = data.results.map(function (x) { x.value = x.name; return x; });
              autocompleteCache[cache][term] = results;
              response(autocompleteCache[cache][term]);
            });
          },
          select: function (e, ui) {
            switch (cache) {
              case 'keyword':
                handleAutocompleteKeyword(ui);
                break;
              case 'company':
                handleAutocompleteCompany(ui);
                break;
              default:
                return;
            }
          },
        });

        $input.autocomplete('instance')._renderItem = function (ul, item) {
          var iconSource = icon;
          var el = $("<li>")
            .append("<div class='dt-autocomplete'><img class='dt-autocomplete__icon' src=" + iconSource + "><div class='dt-autocomplete__heading'>" + item.value + "</div><div class='dt-autocomplete__count'></div></div>")
            .appendTo(ul);
          return el;
        };
      }
    });
  });

  // Google maps widget
  $script.ready(['gmaps', 'ui'], function () {
    if (google) {
      var address_input = document.getElementById("id_address");

      if (address_input) {
        new google.maps.places.Autocomplete(address_input, { types: ["geocode"] });
      }
    }
  });
}());
